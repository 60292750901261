import React from 'react';
import Container from 'container';
import Headline from 'headline';
import s from './simple.module.scss';

// Main Component
export default function SimpleTitle({ title, subtitle, dark }) {
  const Tag = subtitle.includes('<p>') ? 'div' : 'p';
  return (
    <Container className={`${s.section} ${dark ? s.dark : ''}`}>
      <Headline headline2 white={dark} html={title} center className={s.headline} />
      <Tag className={s.animate} dangerouslySetInnerHTML={{ __html: subtitle }} />
    </Container>
  );
}
